import { MediaAgency } from "../types/MediaAgency"
import { SORT_BY } from "../constants/sortby"

export const getImageSource = (photo: any, icon = null): string => {
  return photo
    ? `${process.env.GATSBY_IMAGE_API}${photo.publicUrl}`
    : (icon ? `${process.env.GATSBY_IMAGE_API}${icon.publicUrl}` : "/images/group.svg")
}

export const sortByRankDesc = (
  first: Partial<MediaAgency>,
  second: Partial<MediaAgency>
): number => {
  if (!first.verdicts[0]) return 1
  if (!second.verdicts[0]) return -1
  return second.verdicts[0].total - first.verdicts[0].total
}

export const sortByRankAsc = (
  first: Partial<MediaAgency>,
  second: Partial<MediaAgency>
): number => {
  if (!first.verdicts[0]) return -1
  if (!second.verdicts[0]) return -1
  return first.verdicts[0].total - second.verdicts[0].total
}

export const sortByNameAsc = (
  first: Partial<MediaAgency>,
  second: Partial<MediaAgency>
): number => {
  if (first.name.toUpperCase() < second.name.toUpperCase()) {
    return -1
  }
  if (first.name.toUpperCase() > second.name.toUpperCase()) {
    return 1
  }
  return 0
}

export const sortByNameDesc = (
  first: Partial<MediaAgency>,
  second: Partial<MediaAgency>
): number => {
  if (first.name.toUpperCase() < second.name.toUpperCase()) {
    return 1
  }
  if (first.name.toUpperCase() > second.name.toUpperCase()) {
    return -1
  }
  return 0
}

export const sortByLatestUpdatedAsc = (
  first: Partial<MediaAgency>,
  second: Partial<MediaAgency>
): number => {
  return (
    new Date(first.updatedAt).getTime() - new Date(second.updatedAt).getTime()
  )
}

export const sortByLatestUpdatedDesc = (
  first: Partial<MediaAgency>,
  second: Partial<MediaAgency>
): number => {
  return (
    new Date(second.updatedAt).getTime() - new Date(first.updatedAt).getTime()
  )
}

export const sortMediaAgencies = (agencies, sortBy, sortByValue) => {
  if (sortBy === SORT_BY.RANK) {
    return sortByValue[sortBy] ? [...agencies].sort(sortByRankAsc) : [...agencies].sort(sortByRankDesc)
  } else if (sortBy === SORT_BY.LATEST_UPDATED) {
    return sortByValue[sortBy] ? [...agencies].sort(sortByLatestUpdatedAsc) : [...agencies].sort(sortByLatestUpdatedDesc)
  } else {
    return sortByValue[sortBy] ? [...agencies].sort(sortByNameAsc) : [...agencies].sort(sortByNameDesc)
  }
}


export const getCredibilityByScore = (score) => {
  if (score >= -36 && score < 0) {
    return { key: 'credibility.1', color: '#FFFFFF', bgColor: '#ab281a' }
  } else if (score >= 0 && score < 16) {
    return { key: 'credibility.2', color: '#FFFFFF', bgColor: '#eb412f' }
  } else if (score >= 16 && score < 26) {
    return { key: 'credibility.3', color: '#FFFFFF', bgColor: '#f7a72a' }
  } else if (score >= 26 && score < 36) {
    return { key: 'credibility.4', color: '#FFFFFF', bgColor: '#facf19' }
  } else if (score >= 36 && score < 46) {
    return { key: 'credibility.5', color: '#FFFFFF', bgColor: '#0eb48d' }
  } else if (score >= 46 && score < 56) {
    return { key: 'credibility.6', color: '#FFFFFF', bgColor: '#00cf7f' }
  }
}

export const getCredibilityByLabel = (label) => {
  if (label === '1') {
    return { key: 'credibility.1', color: '#FFFFFF', bgColor: '#ab281a' }
  } else if (label === '2') {
    return { key: 'credibility.2', color: '#FFFFFF', bgColor: '#eb412f' }
  } else if (label === '3') {
    return { key: 'credibility.3', color: '#FFFFFF', bgColor: '#f7a72a' }
  } else if (label === '4') {
    return { key: 'credibility.4', color: '#FFFFFF', bgColor: '#facf19' }
  } else if (label === '5') {
    return { key: 'credibility.5', color: '#FFFFFF', bgColor: '#0eb48d' }
  } else if (label === '6') {
    return { key: 'credibility.6', color: '#FFFFFF', bgColor: '#00cf7f' }
  }
}