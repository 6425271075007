import React from "react"
import { Link } from "gatsby-plugin-intl"

import CredibilityBadge from "../credibility-badge"
import { getImageSource } from "../../utils/utils"
import { MediaAgency } from "../../types/MediaAgency"
import Separator from "../separator"
import * as R from 'ramda'

const AgencyCard = ({
  agency,
  separator = true,
  externalLink = false,
  credibilityBadge = false,
}: {
  agency: Partial<MediaAgency>
  separator?: boolean
  externalLink?: boolean
  credibilityBadge?: boolean
}): JSX.Element => {
  const { verdicts, path, photo, icon, name, url } = agency
  const total = verdicts && verdicts.length ? verdicts[0].total : "-"
  const credibilityLabel = R.pathOr(null, ['verdicts', 0, 'credibilityLabel'], agency)

  return (
    <div
      className="media-agency-card mb-4"
    >
      <Link
        to={`/media-agency/${path}/`}
        className="align-items-center d-flex flex-column"
      >
        <img
          src={getImageSource(photo, icon)}
          width="55"
          alt=""
          className="media-agency-icon"
        />
        {total !== '-' && credibilityBadge ? <CredibilityBadge score={total} credibility={credibilityLabel} /> : null}
      </Link>
      <div>
        {separator && <Separator />}
        <div className="media-agency-card-titles">
          <h5>{externalLink ? name : ("www." + url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0])}</h5>
          <div className="card-text">
            <img width={17} height={17} src="/images/rating.png" />
            {total}
          </div>
        </div>
        {url && externalLink ? (
          <a className="card-link" href={url} target="_blank" rel="noopener noreferrer">
            {"www." + url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0]}
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default AgencyCard
