import React from 'react'
import { getCredibilityByLabel, getCredibilityByScore } from '../utils/utils'
import { useIntl } from 'gatsby-plugin-intl'

const CredibilityBadge = ({ score, credibility }) => {
  const intl = useIntl()
  const result = credibility ? getCredibilityByLabel(credibility) : getCredibilityByScore(score)

  return (
    <h5 className="credibility-badge mt-4 text-center">
      <span className="badge badge-secondary">{intl.formatMessage({ id: `${result.key}` })}</span>
    </h5>
  )
}

export default CredibilityBadge